<template>
  <div>
    <b-modal
      :ref="'dashboard-modal-'+code"
      :id="'dashboard-modal-'+code"
      :title="title"
      size="xl"
    > 
      <b-card>
        <b-tabs card>
            <b-tab :title="subtitle">
                <v-client-table v-if="list_type == 'claims'" :data="list_data" :columns="claims_columns" :options="claims_options" :theme="theme" id="dataTableClaims" class="table">
                  <template slot="actions" slot-scope="props">
                    <b-button variant="outline-dark" class="btn-sm mr-2" :to="{ name: 'Claim', params: { id: props.row.id } }">View</b-button>
                  </template>
                </v-client-table>
                <v-client-table v-if="list_type == 'tasks'" :data="list_data" :columns="tasks_columns" :options="tasks_options" :theme="theme" id="dataTableTasks" class="table">
                  <template slot="actions" slot-scope="props">
                    <b-button variant="outline-dark" class="btn-sm mr-2" :to="{ name: 'Task', params: { id: props.row.id } }">View Task</b-button>
                    <b-button v-if="['Claim', 'Complaint'].includes(props.row.type)" variant="outline-dark" class="btn-sm mr-2" :to="{ name: props.row.type, params: { id: props.row.id } }">View {{ props.row.type }}</b-button>
                  </template>
                </v-client-table>
            </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
Vue.use(ClientTable)

  export default {
    props: {
      list_type: {
        required: true,
      },
      list_data: {
        required: true,
      },
      subtitle: {
        required: true,
      },
      code: {
        required: true,
      }
    },
    data() {
      return {
        title: this.list_type.ucwords() + " From Dashboard Query",
        claims_columns: [
          'created', 'claim_number', 'claim_type', 'claim_sub_type', 'policy_number', 'customer_name', 'status', 'actions',
        ],
        tasks_columns: [
          'created_at', 'type', 'due_date', 'status_pretty', 'assigned_to_pretty', 'description', 'actions',
        ],
        claims_options: {
          headings: {
            claim_number: "Claim Number",
            claim_type: "Claim Type",
            claim_sub_type: "Claim Sub Type",
            policy_number: "Policy Number",
            customer_name: "Customer Name",
          },
          sortable: [
            'created', 'claim_number', 'claim_type', 'claim_sub_type', 'policy_number', 'customer_name', 'status',
          ],
          filterable: [],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          perPage: 5,
        },
        tasks_options: {
          headings: {
            created_at: "Created",
            type: "Task Type",
            due_date: "Due Date",
            status_pretty: "Status",
            assigned_to_pretty: "Assigned To",
          },
          sortable: [
            'created_at', 'type', 'due_date', 'status_pretty', 'assigned_to_pretty', 'description',
          ],
          filterable: [],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          perPage: 5,
        },
        theme: 'bootstrap4',
        template: 'default',
      }
    }
  }
</script>
<style>

  #dataTable  table {
    background-color: #fff;
  }

  .form-inline label {
    align-items: left;
  }


</style>
