<template>
  <div class="animated fadeIn" v-show="loaded">

    <b-card>

      <b-checkbox v-model="live"/> &nbsp;&nbsp;Live View

      <b-select v-model="refresh">
        <b-select-option value="10">10 Seconds</b-select-option>
        <b-select-option value="30">30 Seconds</b-select-option>
        <b-select-option value="60">1 Minute</b-select-option>
        <b-select-option value="300">5 Minutes</b-select-option>
      </b-select>

      <table v-for="(val, key) in details" :key="key">
        <tr>
          <th class="p-2" colspan="2">{{ key.ucwords() }}</th>
        </tr>
        <tr v-for="(v, k) in val" :key="key+'_'+k">
          <td class="p-4" style="height: 60px; vertical-align: top;">
            <font-awesome-layers style="width: 50px; margin-top: 10px;">
              <font-awesome-icon :icon="['far', 'clipboard']" size="4x" :style="{color: v.colour}"></font-awesome-icon>
              <font-awesome-layers-text v-if="v.amount && v.hasOwnProperty('count') && (v.count === 0 || v.count > 0)" :value="'£'+v.amount+'/' + v.count" class="dash_text"></font-awesome-layers-text>
              <font-awesome-layers-text v-else-if="v.amount" :value="'£'+v.amount" class="dash_text"></font-awesome-layers-text>
              <font-awesome-layers-text v-else-if="v.hasOwnProperty('percent')" :value="v.percent.toFixed(2)" class="dash_text"></font-awesome-layers-text>
              <font-awesome-layers-text v-else-if="v.hasOwnProperty('count') && (v.count === 0 || v.count > 0)" :value="v.count" class="dash_text"></font-awesome-layers-text>
              <font-awesome-icon v-else-if="v.hasOwnProperty('count') && v.count === 'X'" :icon="['fas', 'exclamation-triangle']" size="lg" :style="{color: '#ff5400'}" class="mt-1"></font-awesome-icon>
            </font-awesome-layers>
          </td>
          <td class="p-2">
            <table v-if="v.code == 'outstanding_task_oldest'" style="margin-top: 15px;">
              <tr>
                <th colspan="2" class="p-2">
                  <b-link v-if="v.data.type === 'Complaint'" :to="{ name: 'Complaint', params: { id: v.data.object.id } }">{{ v.name }}</b-link>
                  <b-link v-else-if="v.data.type === 'Claim'" :to="{ name: 'Claim', params: { id: v.data.object.id } }">{{ v.name }}</b-link>
                  <span v-else>{{ v.name }}</span>
                </th>
              </tr>
              <tr>
                <td class="p-2">Created:</td><td class="p-2">{{ v.data.created_at }}</td>
              </tr>
              <tr>
                <td class="p-2">Due Date:</td><td class="p-2">{{ v.data.due_date }}</td>
              </tr>
              <tr>
                <td class="p-2">Description:</td><td class="p-2">{{ v.data.description }}</td>
              </tr>
              <tr>
                <td class="p-2">Assigned To:</td><td class="p-2">{{ v.data.assigned_to_pretty }}</td>
              </tr>
            </table>
            <table v-else-if="v.code == 'outstanding_claim_oldest'" style="margin-top: 15px;">
              <tr>
                <th colspan="2" class="p-2"><b-link :to="{ name: 'Claim', params: { id: v.data.id } }">{{ v.name }}</b-link></th>
              </tr>
              <tr>
                <td class="p-2">Created:</td><td class="p-2">{{ v.data.created_at }}</td>
              </tr>
              <tr>
                <td class="p-2">Claim Type:</td><td class="p-2">{{ v.data.claim_type }}</td>
              </tr>
              <tr>
                <td class="p-2">Claim Reference:</td><td class="p-2">{{ v.data.policy_number }}</td>
              </tr>
              <tr>
                <td class="p-2">Status:</td><td class="p-2">{{ v.data.status }}</td>
              </tr>
            </table>
            <template v-else-if="v.data && v.data.length > 0">
              <dashboard-modal :list_type="key" :list_data="v.data" :subtitle="v.name" :code="v.code"></dashboard-modal>
              <b-link v-if="(v.data.length > 0)" v-b-modal="'dashboard-modal-'+v.code">{{ v.name }}</b-link>
            </template>
            <span v-else-if="v.name.length > 0 && v.name[0] === '%'">
              <font-awesome-icon :icon="['fas', 'percentage']" size="lg" :style="{color: 'black'}" class="mr-2"></font-awesome-icon>{{ v.name.replace('% ', '') }}</span>
            <span v-else>{{ v.name }}</span>
          </td>
        </tr>
      </table>
    </b-card>     
  </div>
</template>

<script>
import axios from '../../shared/axios/auth-no-loader'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import { faPercentage, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import DashboardModal from './utils/modal/dashboard-modal'  
import { mapGetters, mapActions } from 'vuex'

library.add(faClipboard, faPercentage, faExclamationTriangle)

export default {
  name: 'dashboard',
  components: {
    FontAwesomeLayers,
    FontAwesomeLayersText,
    'dashboard-modal': DashboardModal
  },
  data: function () {
    return {
      loaded: false,
      details: null,
      live: false,
      refresh: "30", 
      timer: null,
      timeout: null,
      warning_timeout: null,
      inactivity: 300, // seconds until inactive and live canx // inactivity_multiple should be 10,
      inactivity_multiple: 3, // refresh * this (how many refreshed before warning)
      inactivity_warning: 10, // seconds to deduct from timeout to show warning
    }
  },
  computed: {
    ...mapGetters({
      dash_cancel: 'getDashCancel',
    }),
  },
  methods: {
    ...mapActions([
      'setDashCancel',
    ]),
    getData() {
      console.log("Getting dashboard data")
      let url = "/dashboard/"
      axios.get(url).then(
        response => {
          this.details = response.data
          console.log(this.details)
          this.loaded = true
        }
      ).catch(error => {
          // Unauthorised
          if (error.status === 401) {
            this.$store.dispatch('logout');
          }
          console.log('dashboard error');
          console.log(error.response)
        })
      return this.details
    },
    setRefresh(){
      console.log("Setting dashboard refresh timer: "+(this.refresh / 60).toFixed(2)+" mins")
      this.timer = setInterval(() => {
        this.getData()
      }, (parseInt(this.refresh) * 1000));
      this.getData()
    },
    cancelRefresh(){
      console.log("Cancelling refresh timer")
      clearInterval(this.timer);
      this.timer = null;
    },
    setInactivity(){
      console.log("Setting dashboard inactivity timer: "+(this.inactivity / 60).toFixed(2)+" mins")
      this.timeout = setTimeout(() => {
        this.live = false;
      }, (parseInt(this.inactivity) * 1000));
      this.warning_timeout = setTimeout(() => {
        this.$parent.showAlert({type: "danger", text: "Cancelling live view due to inactivity", timer: this.inactivity_warning})
      }, (parseInt(this.inactivity - this.inactivity_warning) * 1000));
    },
    cancelInactivity(){
      console.log("Cancelling inactivity timer")
      clearTimeout(this.timeout)
      this.timeout = null;
      clearTimeout(this.warning_timeout)
      this.warning_timeout = null;
    },
  },
  mounted() {
      this.getData()
  },
  beforeDestroy(){
    console.log("Destroying dashboard")
    this.live = false;
    this.setDashCancel(false);
  },
  watch: {
    live: function(newval, oldval){
      console.log("LIVE", newval)
      if(newval === true){
        this.setRefresh()
        this.setInactivity()
      } else {
        this.cancelRefresh()
        this.cancelInactivity()
      }
    },
    refresh: function(newval, oldval){
      this.live = false;
      this.inactivity = (parseInt(newval) * this.inactivity_multiple)
      this.live = true;
    },
    dash_cancel: function(newval, oldval){
      console.log("DASH CANX", newval)
      if(newval === true){
        this.cancelInactivity();
        this.setInactivity();
        this.setDashCancel(false);
      }
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
  .dash_text {
    margin-top: 4px; 
    background-color: white; 
    padding-left: 1px; 
    padding-right: 1px; 
    border-radius: 4px;
  }
</style>
